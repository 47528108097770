<template>
  <ModalAlert
    :show="showFlg"
    :close-button="props.closeButton"
    :z-index="props.zIndex"
    :title="props.title"
    :size="props.size"
    :is-enable-ok="false"
    :is-enable-cancel="false"
    @close="onClose"
  >
    <div class="w-full max-h-full overflow-y-auto" :class="getClassName()">
      <div class="layout-container">
        <ul class="flex flex-col">
          <li v-for="(item) in props.selectItemList" :key="item.key" class="select-item" @click="onSelectItem(item.key)">
            <div v-if="item.key == props.selectItem" class="i-btn i-btn-info on h-full w-full">
              <div class="relative flex flex-row justify-center items-center">
                <img src="~/assets/images/common/common_check_on.png" class="check-mark">
                <div class="check-text">{{ item.value }}</div>
              </div>
            </div>
            <div v-else class="i-btn i-btn-info h-full w-full">
              <div class="relative flex flex-row justify-center items-center">
                <img src="~/assets/images/common/common_check_off.png" class="check-mark">
                <div class="check-text">{{ item.value }}</div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </ModalAlert>
</template>

<script lang="ts" setup>
type SelectItem = {
  key: string
  value: string
};
const props = withDefaults(defineProps<{
  title: string,
  show?: boolean,
  size?: ModalSizeType,
  closeButton?: boolean,
  // eslint-disable-next-line vue/require-default-prop
  zIndex?: number,
  selectItemList: Array<SelectItem>,
  selectItem: string,
}>(), {
  show: false,
  size: 'auto',
  closeButton: true,
});
interface Emits {
  (e: 'close'): void;
  (e: 'selectItem', selectItem: string): void;
}
const emit = defineEmits<Emits>();
const showFlg = ref<boolean>(false);
onMounted(() => {
  showFlg.value = props.show;
});
watch(() => props.show, (current) => {
  showFlg.value = current;
});

const okSe = useSe(0, '2001');
/**
 * モーダルクローズ
 */
const onClose = () => {
  emit('close');
};

/**
 * アイテム選択
 * @param {string} selectItem
 */
const onSelectItem = (selectItem: string) => {
  okSe?.play();
  emit('selectItem', selectItem);
};

/**
 * クラス名を取得
 */
const getClassName = () => {
  return {
    'modal-max-height': props.size === 'auto',
  };
};
</script>

<style lang="scss" scoped>
.check-mark {
  position: absolute;
  width: 18.6666px;
  height: 18.6666px;
  left: -8px;
  margin-bottom: -2px;
}

.check-text {
  font-size: 0.8rem;
  text-align: center;
}

.select-item {
  &:not(:first-child) {
    margin-top: 0.25rem;
  }

  &:not(:last-child) {
    margin-bottom: 0.25rem;
  }
}

.modal-max-height {
  max-height: 70vh;
}
</style>
